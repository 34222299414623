<template>
  <div
    class="d-flex justify-end"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <o-btn-square
      v-for="(item, type) in value"
      :key="`item-${type}`"
      class="mx-1 square-button"
      color="primary"
      :outlined="!item.display"
      @click="toggle(type)"
    >
      <v-icon size="17">
        {{ item.icon }}
      </v-icon>
    </o-btn-square>
  </div>
</template>

<script>
  import clone from 'lodash/clone';

  export default {
    props: {
      value: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      iconSize: 20,
      buttonHeight: 30,
      buttonWidth: 30,
    }),
    methods: {
      toggle (type) {
        const val = clone(this.value);
        val[type].display = !val[type].display;
        this.$emit('input', val);
      },
    },
  };
</script>
